<template>
  <!-- 职位选择-组件 -->
  <div class="job-select">
    <!-- 选择菜单 -->
    <div class="job-header">
      <div class="select-menu">
        <!-- 菜单 -->
        <div>
          <!-- <i class="iconfont icon-dizhi" style="color:blue"></i> -->

          <el-dropdown
            v-for="(item, index) in jobMenus"
            :key="index"
            class="menu-drop"
          >
            <span class="el-dropdown-link">
              {{ item.title }} <i class="el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(jobItem, jobIdx) in item.menus"
                :key="jobIdx"
                >{{ jobItem.menuName }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- 搜索 -->
        <el-input
          placeholder="请输入内容"
          v-model="searchValue"
          size="medium"
          style="width:260px"
          clearable
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>

    <!-- 职位表 -->
    <div class="job-table">
      <el-table
        ref="jobTable"
        :data="jobTable"
        @row-click="rowClick"
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column type="expand">
          <template slot-scope="scope">
            <div class="job-detail">
              <!-- 职位描述 -->
              <div class="detail-item">
                <p>【职位描述】</p>
                <ul>
                  <li v-for="(item, index) in scope.row.jobDesc" :key="index">
                    {{ `${index + 1}、${item}` }}
                  </li>
                </ul>
              </div>

              <!-- 岗位要求 -->
              <div class="detail-item">
                <p>【岗位要求】</p>
                <ul>
                  <li
                    v-for="(item, index) in scope.row.jobRequirements"
                    :key="index"
                  >
                    {{ `${index + 1}、${item}` }}
                  </li>
                </ul>
              </div>

              <!-- 联系方式 -->
              <div class="detail-item">
                <p>【联系方式】</p>
                <ul>
                  <li>联系人:{{ scope.row.contactType[0] }}</li>
                  <li>电话:{{ scope.row.contactType[1] }}</li>
                  <li>邮箱:{{ scope.row.contactType[2] }}</li>
                </ul>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="职位" prop="position"> </el-table-column>
        <el-table-column label="职位类别" prop="positionType">
          <template v-slot="scope">
            <span>{{ scope.row.positionType | positionFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="工作地点" prop="workPlace"> </el-table-column>
        <el-table-column label="招聘人数" prop="recruitment"> </el-table-column>
        <el-table-column label="更新时间" prop="">
          <template v-slot="scope">
            <span>{{ scope.row.createdAt | dateFormat }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/jobs";

export default {
  name: "jobSelect",
  props: {},
  data() {
    return {
      jobMenus: [
        {
          title: "全部",
          menus: [
            // {
            //   menuName: "全部1",
            // },
            // {
            //   menuName: "全部2",
            // },
            // {
            //   menuName: "全部3",
            // },
          ],
        },
        {
          title: "社会招聘",
          menus: [
            // {
            //   menuName: "社招1",
            // },
            // {
            //   menuName: "社招2",
            // },
            // {
            //   menuName: "社招3",
            // },
          ],
        },
        {
          title: "全部职位",
          menus: [
            // {
            //   menuName: "职位1",
            // },
            // {
            //   menuName: "职位2",
            // },
            // {
            //   menuName: "职位3",
            // },
          ],
        },
      ], // 职位菜单
      searchValue: "", // 搜索值
      jobTable: [
        {
          position: "知识图谱应用方案经理",
          positionType: "产品",
          workPlace: "广州",
          recruitment: "1",
          updateTime: "2020-07-23",
          jobDesc: [
            "负责金融行业知识图谱应用方案研究、售前、需求调研，并提出适度超前的软件解决方案",
            "负责银行业知识图谱应用场景研究，并形成方案",
            "负责相关项目的需求分析工作；",
          ],
          jobRequirements: [
            "计算机相关专业大学本科及以上学历，硕士优先",
            "要求熟悉知识图谱应用，有2年以上的软件需求分析、实施、售前或产品设计经验",
            "对数据库（MySql、HIVE、图数据库）有一定的了解，对新技术框架有一定的了解",
            "具有良好的团队合作精神",
          ],
          contactType: ["陈老师", "18688682158", "service@realfinance.com.cn"],
        },
        {
          position: "前端工程师",
          positionType: "开发",
          workPlace: "广州、不限",
          recruitment: "1",
          updateTime: "2020-07-23",
          jobDesc: [
            "负责Web/App的视觉设计",
            "根据产品原型，设定产品的整体风格，完成高保真视觉效果设计",
            "负责跟踪产品效果及用户体验，提出设计优化方案",
            "负责对公司线上活动广告宣传进行创意策划、排版、美工设计",
          ],
          jobRequirements: [
            "2年以上移动互联网UI设计工作经验，熟悉Web、App设计规范",
            "精通前端开发框架VUE及JS、H5",
            "精通Photoshop、Illustrator、CorelDraw等设计软件",
            "有较高的审美意识、创新设计能力和团队合作意识",
            "能切页面懂代码者（Html+CSS等）优先，具有移动互联网Web/App设计经验者优先",
          ],
          contactType: ["陈老师", "18688682158", "service@realfinance.com.cn"],
        },
        {
          position: "知识图谱应用方案经理",
          positionType: "产品",
          workPlace: "广州",
          recruitment: "1",
          updateTime: "2020-07-23",
          jobDesc: [
            "负责金融行业知识图谱应用方案研究、售前、需求调研，并提出适度超前的软件解决方案",
            "负责银行业知识图谱应用场景研究，并形成方案",
            "负责相关项目的需求分析工作",
          ],
          jobRequirements: [
            "计算机相关专业大学本科及以上学历，硕士优先",
            "要求熟悉知识图谱应用，有2年以上的软件需求分析、实施、售前或产品设计经验",
            "对数据库（MySql、HIVE、图数据库）有一定的了解，对新技术框架有一定的了解",
            "具有良好的团队合作精神",
          ],
          contactType: ["陈老师", "18688682158", "service@realfinance.com.cn"],
        },
        {
          position: "金融行业区域总监（合伙人）",
          positionType: "产品",
          workPlace: "广州",
          recruitment: "1",
          updateTime: "2020-07-23",
          jobDesc: [
            "金融或是计算机相关专业专科以上学历，熟悉咨询或是软件销售业务",
            "能够有效挖掘潜在客户并持续跟踪",
            "良好的客户沟通能力，积极上进，较强的独立工作能力",
            "有一年以上高校或金融行业软件、解决方案或是咨询业务的销售经验",
            "强烈的责任心和团队合作精神",
          ],
          jobRequirements: [
            "在公司领导下，和各部门密切配合完成工作",
            "制订销售计划，确定销售政策",
            "新客户的开发老客户的维护，与客户人际关系的确立",
            "销售目标与定额的设订和管理",
            "科学而有效的营业分析，客户的计量管理",
            "完成落实销售总监安排的其他工作",
          ],
          contactType: ["陈老师", "18688682158", "service@realfinance.com.cn"],
        },
        {
          position: "教育行业销售经理（合伙人）",
          positionType: "产品",
          workPlace: "北京、上海、广州、长沙、武汉、成都",
          recruitment: "1",
          updateTime: "2020-07-23",
          jobDesc: [
            "在公司领导下，和各部门密切配合完成工作",
            "制订销售计划，确定销售政策",
            "新客户的开发老客户的维护，与客户人际关系的确立",
            "销售目标与定额的设订和管理",
            "科学而有效的营业分析，客户的计量管理",
            "完成落实销售总监安排的其他工作",
          ],
          jobRequirements: [
            "金融或是计算机相关专业专科以上学历，熟悉咨询或是软件销售业务",
            "能够有效挖掘潜在客户并持续跟踪",
            "良好的客户沟通能力，积极上进，较强的独立工作能力",
            "有一年以上教育行业软件、解决方案或是咨询业务的销售经验",
            "强烈的责任心和团队合作精神",
            "有高校沙盘课程营销、高校大数据与人工智能产品销售经历优先",
            "有高校资源优先",
          ],
          contactType: ["陈老师", "18688682158", "service@realfinance.com.cn"],
        },
      ], // 职位表格
    };
  },
  created() {
    this.getList();
  },
  filters: {
    positionFilter(value) {
      let types = {
        1: "开发",
        2: "产品",
        3: "销售",
      };

      return types[value];
    }, // 职位过滤
  },
  methods: {
    async getList() {
      let res = await getList();
      let temp = res.data._embedded.jobs;
      temp = temp.map((item) => {
        return { ...item, ...JSON.parse(item.content) };
      });
      this.jobTable = temp;
    }, // 获取职位列表
    rowClick(row, column) {
      this.$refs.jobTable.toggleRowExpansion(row);
    }, // 切换行展开
  },
};
</script>

<style lang="less" scoped>
.job-select {
  .job-header {
    height: 73px;
    background-color: #f7f8f8;

    .menu-drop {
      margin-right: 35px;
    }

    .select-menu {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      padding: 0 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      /deep/ .el-input {
        width: 260px;

        .el-input__inner {
          background-color: transparent;
        }

        .el-input-group__append {
          color: #fff;
          background-color: #409eff;
          border-color: #409eff;
        }
      }
    }
  }

  .job-table {
    width: 1200px;
    margin: 0 auto;
    padding: 30px;

    /deep/ .el-table__expanded-cell {
      padding: 0;
    }

    .job-detail {
      padding: 20px 40px;

      background-color: #f7f8f8;

      .detail-item {
        font-size: 14px;
        line-height: 28px;
        p {
          color: var(--main-title_black);
        }

        li {
          color: var(--main-memo_gray);
        }
      }
    }
  }
}
</style>
