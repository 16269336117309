<template>
  <div>
    <!-- 产品介绍 -->
    <product-brief
      height="340"
      :title="currentData && currentData.productBrief.title"
      :memo="currentData && currentData.productBrief.memo"
      :img-src="currentData && currentData.productBrief.imgSrc"
    />

    <!-- 职位筛选 -->
    <job-select />
  </div>
</template>

<script>
import jobSelect from "./components/job-select"; // 瑞友简介-组件

export default {
  components: {
    jobSelect,
  },
  data() {
    return {
      currentData: {
        productBrief: {
          title: "期待你的加入",
          memo: ["我们希望成功路上有靠谱的你!"],
          imgSrc: "https://s1.ax1x.com/2020/07/27/aPrSKO.png",
        }, // 介绍组件数据
      },
    };
  },
};
</script>

<style></style>
